<template>
  <div>
    <h3 class="mb-3">Listing Tours
      <button 
        type="button"
        class="btn btn-primary float-right" 
        data-toggle="modal" 
        data-target="#tourModal"
      >Add New Tour Package</button>
    </h3>
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Country</th>
          <th class="text-center">Featured</th>
          <th>Price</th>
          <th class="text-center">Categories</th>
          <th class="text-center">Days</th>
          <th class="text-center text-primary">Edit</th>
          <th class="text-center text-danger">Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tour, i) in tours" :key="tour.id">
          <td>{{ tour.name }}</td>
          <td>{{ tour.country.name }}</td>
          <td class="text-center">{{ tour.featured ? 'Yes' : '---' }}</td>
          <td>{{ tour.price ? formatter.format(tour.price) : '---' }}</td>
          <td class="text-center">{{ tour.categories.length }}</td>
          <td class="text-center">{{ tour.days.length }}</td>
          <td class="text-center"><a @click.prevent="loadTour(i)" class="text-primary" href="#">Edit</a></td>
          <td class="text-center"><a @click.prevent="removeTour(tour.id, i)" class="text-danger" href="#">Delete</a></td>
        </tr>
      </tbody>
    </table>

    <!-- Tour Modal -->
    <div class="modal fade" id="tourModal" 
      data-backdrop="static" 
      data-keyboard="false" 
      tabindex="-1" 
      aria-labelledby="tourModalLabel" 
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ !edit ? 'Add New Tour' : 'Edit Tour' }}</h5>
            <button @click="clearTourForm" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="tourForm" id="tourForm">
              <div class="row">
                <div class="col-lg-8 form-group">
                  <label for="name">Tour Name</label>
                  <input type="text" name="name" v-model="tour.name" id="name" class="form-control">
                </div>
                <div class="col-lg-4 form-group">
                  <label for="country_id">Select Country</label>
                  <select name="country_id" v-model="tour.countryID" id="country_id" class="form-control">
                    <option value="" selected disabled>--- Select Country ---</option>
                    <option v-for="country in countries" :value="country.id" :key="country.id">{{ country.name }}</option>
                  </select>
                </div>
              </div>
              <!-- <div class="form-group">
                <label for="overview">Tour Overview</label> 
                <span v-if="tour.overview" class="text-black-50 float-right">{{ tour.overview.length }} characters</span>
                <textarea name="overview" v-model="tour.overview" id="overview" cols="30" rows="2" maxlength="160" class="form-control"></textarea>
              </div> -->
              <div class="form-group">
                <label for="seo_title">Enter SEO Title</label> 
                <span class="float-right text-black-50" v-if="tour.seoTitle">{{ tour.seoTitle.length }} characters</span>
                <input type="text" name="seo_title" v-model="tour.seoTitle" id="seo_title" maxlength="70" class="form-control">
              </div>
              <div class="form-group">
                <label for="meta_description">Enter Meta Description</label>
                <span class="float-right text-black-50" v-if="tour.metaDescription">{{ tour.metaDescription.length }} characters</span>
                <textarea name="meta_description" v-model="tour.metaDescription" id="meta_description" maxlength="160" cols="30" rows="2" class="form-control"></textarea>
              </div>
              <div class="row">
                <div class="col-6 form-group">
                  <label for="featured">Featured?</label>
                  <select name="featured" v-model="tour.featured" id="featured" class="form-control">
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                </div>
                <div class="col-6 form-group">
                  <label for="price">Tour Price</label>
                  <input type="number" name="price" v-model="tour.price" min="1" id="price" class="form-control">
                </div>
                <div class="col-lg-6 form-group">
                  <div v-if="!tour.headerPhoto">
                    <label for="header_photo">Header Photo</label> <span class="text-black-50 float-right">( 1600 x 600 )</span>
                    <input @change="selectHeaderPhoto($event)" type="file" class="form-control">
                  </div>
                  <div v-else>
                    <div v-if="tour.headerPhoto && !tour.headerPhotoPreview">
                      <img :src="`${baseServerURL}/storage/tour_header_photos/${tour.headerPhoto}`" class="previewImg" alt="Preview">
                      <button @click.prevent="tour.headerPhoto = ''" class="btn btn-warning ml-3">Remove Photo</button>
                    </div>
                    <div v-else-if="tour.headerPhoto && tour.headerPhotoPreview">
                      <img :src="tour.headerPhotoPreview" class="previewImg" alt="Preview">
                      <button @click.prevent="tour.headerPhoto = ''; tour.headerPhotoPreview = ''" class="btn btn-warning ml-3">
                        Remove Photo
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 form-group">
                  <div v-if="!tour.featurePhoto">
                    <label for="feature_photo">Feature Photo</label> <span class="text-black-50 float-right">( 800 x 800 )</span>
                    <input @change="selectFeaturePhoto($event)" type="file" class="form-control">
                  </div>
                  <div v-else>
                    <div v-if="tour.featurePhoto && !tour.featurePhotoPreview">
                      <img :src="`${baseServerURL}/storage/tour_feature_photos/${tour.featurePhoto}`" class="previewImg" alt="Preview">
                      <button @click.prevent="tour.featurePhoto = ''" class="btn btn-warning ml-3">Remove Photo</button>
                    </div>
                    <div v-else-if="tour.featurePhoto && tour.featurePhotoPreview">
                      <img :src="tour.featurePhotoPreview" class="previewImg" alt="Preview">
                      <button @click.prevent="tour.featurePhoto = ''; tour.featurePhotoPreview = ''" class="btn btn-warning ml-3">
                        Remove Photo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <h6 class="card-header">Tour Categories</h6>
                <div class="card-body">
                  <div v-if="edit" class="row">
                    <div v-for="existingCategory in tour.categories" class="col-md-6 col-lg-4" :key="existingCategory.id">
                      <label>
                        <input type="checkbox" name="existing_categories[]" :value="existingCategory.id" checked> 
                        {{ existingCategory.name }}
                      </label>
                    </div>
                    <div v-for="addedCategory in addedTourCategories" class="col-md-6 col-lg-4" :key="addedCategory.id">
                      <label>
                        <input type="checkbox" name="added_categories[]" :value="addedCategory.id"> 
                        {{ addedCategory.name }}
                      </label>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div v-for="tourCategory in tourCategories" class="col-md-6 col-lg-4" :key="tourCategory.id">
                      <label>
                        <input type="checkbox" name="categories" :value="tourCategory.id"> {{ tourCategory.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- Tour Days Card -->
              <div class="mt-3">
                <div class="card" v-for="(day,i) in tour.days" :key="i">
                  <h6 class="card-header">Tour Day {{ i+1 }} Title, Description & Photos - <em>activities</em></h6>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-2 form-group">
                        <label for="sequence">Sequence</label>
                        <input type="number" :value="(i)" id="day_sequence" :name="`day[${i}][sequence]`"
                          class="form-control form-control-sm" :readonly="!edit"
                        >
                      </div>
                      <div class="col-lg-10 form-group">
                        <label for="day_title">Day {{ i+1 }} Title</label>
                        <input type="text" v-model="day.title" id="day_title" class="form-control form-control-sm">
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="day_description">Day {{ i+1 }} Description</label>
                      <ckeditor :editor="editor" v-model="day.description" :config="editorConfig"></ckeditor>
                      <!-- <textarea
                        v-model="day.description" id="day_description" cols="30" rows="4" class="form-control form-control-sm">
                      </textarea> -->
                    </div>
                    <div class="row">
                      <div class="col-lg-6 form-group">
                        <div v-if="!day.photo1">
                          <label for="day_photo1">
                          Day {{ i+1 }} Photo1 - <em>800 x 400</em>
                          </label> 
                          <small class="text-black-50"> (optional)</small>
                          <input @change="selectTourDayPhoto1($event, i)" type="file" id="day_photo1" class="form-control form-control-sm">
                        </div>
                        <div v-else>
                          <div v-if="day.photo1 && !day.photo1Preview">
                            <img :src="`${baseServerURL}/storage/tour_day_photos/${day.photo1}`" class="previewImg" alt="Preview">
                            <button @click.prevent="day.photo1 = ''" class="btn btn-warning ml-3">Remove Photo</button>
                          </div>
                          <div v-else-if="day.photo1 && day.photo1Preview">
                            <img :src="day.photo1Preview" class="previewImg" alt="Preview">
                            <button @click.prevent="day.photo1 = ''; day.photo1Preview = ''" class="btn btn-warning ml-3">
                              Remove Photo
                            </button>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <div class="col-lg-6 form-group">
                        <div v-if="!day.photo2">
                          <label for="day_photo2">
                          Day {{ i+1 }} Photo2 - <em>800 x 400</em>
                          </label> 
                          <small class="text-black-50"> (optional)</small>
                          <input @change="selectTourDayPhoto2($event, i)" type="file" id="day_photo2" class="form-control form-control-sm">
                        </div>
                        <div v-else>
                          <div v-if="day.photo2 && !day.photo2Preview">
                            <img :src="`${baseServerURL}/storage/tour_day_photos/${day.photo2}`" class="previewImg" alt="Preview">
                            <button @click.prevent="day.photo2 = ''" class="btn btn-warning ml-3">Remove Photo</button>
                          </div>
                          <div v-else-if="day.photo2 && day.photo2Preview">
                            <img :src="day.photo2Preview" class="previewImg" alt="Preview">
                            <button @click.prevent="day.photo2 = ''; day.photo2Preview = ''" class="btn btn-warning ml-3">
                              Remove Photo
                            </button>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-2">
                    <button @click.prevent="addDayTour(i)" class="btn btn-sm btn-outline-primary">Add Day Below</button>
                    <button @click.prevent="remDayTour(i)" class="btn btn-sm btn-outline-danger float-right">Remove Day {{ i+1 }}</button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="information">Extra Information</label> <small class="text-black-50">(optional)</small>
                <ckeditor :editor="editor" v-model="tour.information" :config="editorConfig"></ckeditor>
              </div>
              <div class="row">
                <div class="col-lg-6 form-group">
                  <label for="includes">What is Included</label>
                  <ckeditor :editor="editor" v-model="tour.includes" :config="editorConfig"></ckeditor>
                </div>
                <div class="col-lg-6 form-group">
                  <label for="excludes">What is Excluded</label>
                  <ckeditor :editor="editor" v-model="tour.excludes" :config="editorConfig"></ckeditor>
                </div>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button @click.prevent="clearTourForm" type="button" class="btn btn-outline-secondary">Clear</button>
            <button @click.prevent="validateData" type="button" class="btn btn-primary">
              {{ !edit ? 'Add New Tour Package' : 'Save Changes' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatter } from '../main.js';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  data() {
    return {
      tour: { 
          id:'', name:'', countryID: '', categories: [], overview: '',
          seoTitle: '', metaDescription: '', price: '', featured: 0, 
          information: '', includes: '', excludes: '',
          headerPhoto: '', headerPhotoPreview:'', featurePhoto: '', featurePhotoPreview: '',
          days: [{ 
            id:'', sequence: '', title:'', description:'', 
            photo1:'', photo2:'', photo1Preview:'', photo2Preview:'' 
          }],
        },
      tours: [],
      tourCategories: [],
      countries: [],
      formatter,
      editor: ClassicEditor,
      editorConfig: {},
      baseServerURL: this.$baseServerURL,
      apiURL: this.$apiURL,
      edit: false,
    }
  },
  mounted() {
    this.getTours();
    this.getCountries();
    this.getTourCategories();
  },
  computed: {
    addedTourCategories() {
      let existingTourCategoryIDs = []; this.tour.categories.forEach(el => existingTourCategoryIDs.push(el.id));
      let allTourCategoryIDs = []; this.tourCategories.forEach(el => allTourCategoryIDs.push(el.id));
      let addedTourCategories = []; allTourCategoryIDs.forEach(el1 => {
        if(!existingTourCategoryIDs.includes(el1)) {
          addedTourCategories.push(this.tourCategories.find(c => c.id === el1));
        }
      })
      return addedTourCategories;
    }
  },
  methods: {
    validateData() {
      this.sendData();
    },
    sendData() {
      let vm = this;
      if(vm.edit) {
        // edit data
        let formData = new FormData(vm.$refs.tourForm);
        formData.append('information', vm.tour.information);
        formData.append('includes', vm.tour.includes);
        formData.append('excludes', vm.tour.excludes);
        formData.append('header_photo', vm.tour.headerPhoto);
        formData.append('feature_photo', vm.tour.featurePhoto);
        formData.append('id', vm.tour.id);
        formData.append('_method', 'PUT');
        
        // append days into FormData
        for (let i = 0; i < vm.tour.days.length; i++) {
          formData.append(`days[${i}][id]`, vm.tour.days[i].id ? vm.tour.days[i].id : '');
          formData.append(`days[${i}][sequence]`, formData.get(`day[${i}][sequence]`)); 
          formData.delete(`day[${i}][sequence]`); // Removing the key used above as no longer needed
          formData.append(`days[${i}][title]`, vm.tour.days[i].title);
          formData.append(`days[${i}][description]`, vm.tour.days[i].description);
          formData.append(`days[${i}][photo1]`, vm.tour.days[i].photo1 ? vm.tour.days[i].photo1 : '');
          formData.append(`days[${i}][photo2]`, vm.tour.days[i].photo2 ? vm.tour.days[i].photo2 : '');
        }

        // for (let p of formData) {
        //   console.log(p)
        // }

        // return;

        vm.$axios.post(`${vm.apiURL}/tours`, formData)
          .then(res => {
            if(res.status === 200) {  // console.log(res)
              // hide the modal
              this.$jquery('#tourModal').modal('hide');

              // update the DOM
              let theTour = this.tours.find(d => d.id === vm.tour.id)
              theTour.country = res.data.data.country;
              theTour.country_id = res.data.data.country_id;
              theTour.categories = res.data.data.categories;
              theTour.days = res.data.data.days;
              theTour.name = res.data.data.name; 
              theTour.price = res.data.data.price; 
              theTour.featured = parseInt(res.data.data.featured); 
              theTour.seo_title = res.data.data.seo_title;
              theTour.meta_description = res.data.data.meta_description;
              theTour.description = res.data.data.description;
              theTour.header_photo = res.data.data.header_photo;
              theTour.feature_photo = res.data.data.feature_photo;

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`).then(res => { 
                if(res.isConfirmed) vm.clearTourForm();
              });
            }
          })
          .catch(err => vm.$swal.fire(`${err.response.data.title}`,`${err.response.data.message}`,`${err.response.data.status}`));
      } else {
        // add data
        let formData = new FormData(vm.$refs.tourForm);
        formData.append('information', vm.tour.information);
        formData.append('includes', vm.tour.includes);
        formData.append('excludes', vm.tour.excludes);
        formData.append('header_photo', vm.tour.headerPhoto);
        formData.append('feature_photo', vm.tour.featurePhoto);
        
        // append days into FormData
        for (let i = 0; i < vm.tour.days.length; i++) {
          formData.append(`days[${i}][id]`, vm.tour.days[i].id ? vm.tour.days[i].id : '');
          formData.append(`days[${i}][sequence]`, formData.get(`day[${i}][sequence]`)); 
          formData.delete(`day[${i}][sequence]`); // Removing the key used above as no longer needed
          formData.append(`days[${i}][title]`, vm.tour.days[i].title);
          formData.append(`days[${i}][description]`, vm.tour.days[i].description);
          formData.append(`days[${i}][photo1]`, vm.tour.days[i].photo1 ? vm.tour.days[i].photo1 : '');
          formData.append(`days[${i}][photo2]`, vm.tour.days[i].photo2 ? vm.tour.days[i].photo2 : '');
        }

        // for (let p of formData) {
        //   console.log(p)
        // }

        // return;

        vm.$axios.post(`${vm.apiURL}/tours`, formData)
          .then(res => {
            if(res.status === 200) {  // console.log(res)
              // hide the modal
              this.$jquery('#tourModal').modal('hide');

              // update the DOM
              let theTour = this.tours.find(d => d.id === vm.tour.id)
              theTour.country = res.data.data.country;
              theTour.country_id = res.data.data.country_id;
              theTour.categories = res.data.data.categories;
              theTour.days = res.data.data.days;
              theTour.name = res.data.data.name; 
              theTour.price = res.data.data.price; 
              theTour.featured = parseInt(res.data.data.featured); 
              theTour.seo_title = res.data.data.seo_title;
              theTour.meta_description = res.data.data.meta_description;
              theTour.description = res.data.data.description;
              theTour.header_photo = res.data.data.header_photo;
              theTour.feature_photo = res.data.data.feature_photo;

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`).then(res => { 
                if(res.isConfirmed) vm.clearTourForm();
              });
            }
          })
          .catch(err => vm.$swal.fire(`${err.response.data.title}`,`${err.response.data.message}`,`${err.response.data.status}`));
      }
    },
    selectHeaderPhoto(e) {
      this.tour.headerPhoto = e.target.files[0]; 
      this.tour.headerPhotoPreview = URL.createObjectURL(this.tour.headerPhoto);
    },
    selectFeaturePhoto(e) {
      this.tour.featurePhoto = e.target.files[0]; 
      this.tour.featurePhotoPreview = URL.createObjectURL(this.tour.featurePhoto);
    },
    selectTourDayPhoto1(e, i) {
      this.tour.days[i].photo1 = e.target.files[0]; this.tour.days[i].photo1Preview = URL.createObjectURL(this.tour.days[i].photo1);
    },
    selectTourDayPhoto2(e, i) {
      this.tour.days[i].photo2 = e.target.files[0]; this.tour.days[i].photo2Preview = URL.createObjectURL(this.tour.days[i].photo2);
    },
    getTours() {
      this.$axios.get(`${this.apiURL}/tours`)
        .then(res => this.tours = res.data)
        .catch(err => console.log(err))
    },
    getTourCategories() {
      this.$axios.get(`${this.apiURL}/tour-categories`)
        .then(res => this.tourCategories = res.data)
        .catch(err => console.log(err))
    },
    getCountries() {
      this.$axios.get(`${this.apiURL}/countries`)
        .then(res => this.countries = res.data)
        .catch(err => console.log(err))
    },
    addDayTour(i) {
      this.tour.days.splice((i + 1), 0, { 
        id:'', sequence: this.tour.days.length + 1, title:'', description:'', photo1:'', 
        photo2:'', photo1Preview:'', photo2Preview:'' 
      })
    },
    remDayTour(i) {
      if(this.tour.days.length > 1) {
        if(this.tour.days[i].id) {
          // deleting db day
          this.$swal.fire({
            title: `Day ${i+1} is Saved in Database. Are you sure you want to delete ?`,
            text:'This will remove all it\'s details including photos if any',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((res) => {
            if (res.isConfirmed) {
              this.$axios.delete(`${this.apiURL}/tour-days/${this.tour.days[i].id}`)
                .then(res => {
                  if(res.status === 200) {
                    this.tour.days.splice(i, 1);
                    this.$swal(
                      `Day ${i+1} have been Removed`, 
                      `The Day that was previously named as Day ${i+1} with all it's details like photos have been completely removed`, 
                      'info'
                    )
                  }
                }
              )
            }
          })
        } else {
          // deleting temp day
          this.$swal.fire({
            title: `Day ${i+1} isn't Saved in Database. Do you want to proceed ?`,
            text:'The day will just be removed from the tour days flow',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((res) => {
            if (res.isConfirmed) {
              this.tour.days.splice(i, 1);
              this.$swal(
                `Day ${i+1} have been Removed`, 
                ``, 
                'info')
              }
            }
          )
        }
      } else {
        this.$swal('How, not even a single day?', 'A valid Tour must have at-least 1 day of activities', 'question')
      } 
    },
    loadTour(i) {
      this.edit = true; this.$jquery('#tourModal').modal('show');

      this.tour.id = this.tours[i].id;
      this.tour.name = this.tours[i].name;
      this.tour.seoTitle = this.tours[i].seo_title;
      this.tour.metaDescription = this.tours[i].meta_description;
      this.tour.countryID = this.tours[i].country_id;
      this.tour.categories = this.tours[i].categories;
      this.tour.overview = this.tours[i].overview;
      this.tour.price = this.tours[i].price;
      this.tour.featured = this.tours[i].featured;
      this.tour.featurePhoto = this.tours[i].feature_photo;
      this.tour.headerPhoto = this.tours[i].header_photo;
      this.tour.information = this.tours[i].information;
      this.tour.includes = this.tours[i].includes;
      this.tour.excludes = this.tours[i].excludes;
      this.tour.categories = this.tours[i].categories;
      this.tour.days = this.tours[i].days;
    },
    removeTour(id, i) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this Tour ?',
        text:'This process is irreversible, you can\'t recover the tour package after confirming this action',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        denyButtonText: `No, don't delete`,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$axios.delete(`${this.apiURL}/tours/${id}`)
            .then(res => {
              if(res.status === 200) {
                this.tours.splice(i, 1);
                this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`);
              }
            })
            .catch(err => this.$swal.fire(`${err.response.data.title}`, `${err.response.data.message}`, `${err.response.data.status}`))
        } else if (res.isDenied) {
          this.$swal.fire('Tour was not deleted..!', '', 'info')
        }
      })
    },
    clearTourForm() {
      
      // clear tour object
      this.tour.id = ''; this.tour.name = ''; this.tour.countryID = ''; this.tour.categories = [], this.tour.days = [],
      this.tour.overview = ''; this.tour.seoTitle = ''; this.tour.metaDescription = ''; this.tour.price = ''; this.tour.featured = 0;
      this.tour.information = ''; this.tour.includes = ''; this.tour.excludes = ''; this.tour.headerPhoto = ''; 
      this.tour.featurePhoto = ''; this.tour.headerPhotoPreview = ''; this.tour.featurePhotoPreview = '';
      
      // clear days array to default single day
      this.tour.days = [{ id:'', sequence: '', title:'', description:'', photo1:'', photo2:'', photo1Preview:'', photo2Preview:'' }];

      // reset defaults
      this.edit = false; document.querySelector('#tourForm').reset();
    }
  }
}
</script>

<style scoped>
.card {
  background: #eee;
  margin-bottom: 30px;
}
.previewImg {
  max-width: 150px;
}
</style>