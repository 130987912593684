<template>
  <div>
    <h3 class="mb-3">
      Listing Countries
      <button class="btn btn-primary float-right" data-toggle="modal" data-target="#countryModal">Add New Country</button>
    </h3>
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>SEO Title</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(country, i) in countries" :key="country.id">
          <td>{{ country.name }}</td>
          <td>{{ country.seo_title }}</td>
          <td><a @click.prevent="loadCountry(i)" href="#">Edit</a></td>
          <td><a @click.prevent="removeCountry(country.id, i)" href="#">Delete</a></td>
        </tr>
      </tbody>
    </table>

    <!-- Country Modal -->
    <div class="modal fade" id="countryModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ !edit ? 'Add New Country' : `Edit ${country.name} Country details` }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="countryForm" id="countryForm">
              <div class="form-group">
                <label for="name">Country Name</label>
                <input type="text" name="name" v-model="country.name" id="name" class="form-control">
              </div>
              <div class="form-group">
                <label for="seo_title">SEO Title</label> 
                <span v-if="country.seoTitle" class="float-right text-black-50">{{ country.seoTitle.length }} characters</span>
                <input type="text" name="seo_title" v-model="country.seoTitle" id="seo_title" class="form-control">
              </div>
              <div class="form-group">
                <label for="meta_description">Meta Description</label> 
                <span v-if="country.metaDescription" class="float-right text-black-50">
                  {{ country.metaDescription.length }} characters
                </span>
                <textarea type="text" name="meta_description" v-model="country.metaDescription" 
                  id="meta_description" cols="30" rows="2" class="form-control"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="description">Country Description</label>
                <textarea name="description" v-model="country.description" 
                  id="description" cols="30" rows="6" class="form-control"
                ></textarea>
              </div>
              <div class="row">
                <div class="col-lg-6 form-group">
                  <div v-if="!country.flag">
                    <label for="flag">Country Flag</label>
                    <input type="file" name="flag" id="flag" class="form-control">
                  </div>
                  <div v-else>
                    <img :src="`${baseServerURL}/storage/country_flags/${country.flag}`" :alt="`${country.name}`">
                    <button @click.prevent="country.flag = ''" class="btn btn-warning ml-3">Remove Photo</button>
                  </div>
                </div>
                <div class="col-lg-6 form-group">
                  <div v-if="!country.photo">
                    <label for="photo">Country Photo</label>
                    <input type="file" name="photo" id="photo" class="form-control">
                  </div>
                  <div v-else>
                    <img :src="`${baseServerURL}/storage/country_photos/${country.photo}`" class="country-photo" :alt="`${country.name}`">
                    <button @click.prevent="country.photo = ''" class="btn btn-warning ml-3">Remove Photo</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button @click.prevent="clearCountryForm" type="button" class="btn btn-outline-secondary">Clear</button>
            <button @click.prevent="validateData" type="button" class="btn btn-primary">
              {{ !edit ? 'Add Country' : 'Save Changes' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countries: [],
      country: { 
        id:'', name:'', seoTitle:'', metaDescription:'', 
        description:'', flag:'', photo:''
      },
      apiURL: this.$apiURL,
      baseServerURL: this.$baseServerURL,
      edit: false
    }
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    validateData() {
      this.sendData()
    },
    sendData() {
      let vm = this;
      if(this.edit) {
        // edit data
        let formData = new FormData(this.$refs.countryForm)
        formData.append('id', vm.country.id)
        formData.append('_method', 'PUT')
        this.$axios.post(`${this.apiURL}/countries`, formData)
          .then(res => {
            if(res.status === 200) {
              // hide the modal
              this.$jquery('#countryModal').modal('hide');

              // update the DOM
              let theCountry = this.countries.find(c => c.id === vm.country.id)
              theCountry.name = res.data.data.name; 
              theCountry.seo_title = res.data.data.seo_title;
              theCountry.meta_description = res.data.data.meta_description; 
              theCountry.description = res.data.data.description;
              theCountry.photo = res.data.data.photo;
              theCountry.flag = res.data.data.flag;

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`)
                .then(res => { 
                  if(res.isConfirmed) vm.clearCountryForm();
                }
              );
            }
          })
          .catch(err => console.log(err))
      } else {
        // add data
        let formData = new FormData(this.$refs.countryForm)
        this.$axios.post(`${this.apiURL}/countries`, formData)
          .then(res => {
            if(res.status === 201) {
              // hide the modal
              this.$jquery('#countryModal').modal('hide');

              // update the DOM
              this.countries.push({
                id: res.data.data.id, name: res.data.data.name, seo_title: res.data.data.seo_title,
                meta_description: res.data.data.meta_description, description: res.data.data.description
              })

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`)
                .then(res => { 
                  if(res.isConfirmed) vm.clearCountryForm();
                }
              );
            }
          })
          .catch(err => console.log(err))
      }
    },
    getCountries() {
      this.$axios.get(`${this.apiURL}/countries`)
        .then(res => this.countries = res.data)
        .catch(err => console.log(err))
    },
    loadCountry(i) {
      this.edit = true; this.$jquery('#countryModal').modal('show');
      
      this.country.id = this.countries[i].id;
      this.country.name = this.countries[i].name;
      this.country.seoTitle = this.countries[i].seo_title;
      this.country.metaDescription = this.countries[i].meta_description;
      this.country.description = this.countries[i].description;
      this.country.flag = this.countries[i].flag
      this.country.photo = this.countries[i].photo
    },
    removeCountry(id, i) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this Country?',
        text:'This process is irreversible, you can\'t recover the country after confirming this action',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        denyButtonText: `No, don't delete`,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$axios.delete(`${this.apiURL}/countries/${id}`)
            .then(res => {
              if(res.status === 200) {
                this.countries.splice(i, 1);
                this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`);
              }
            })
            .catch(err => this.$swal.fire(`${err.response.data.title}`, `${err.response.data.message}`, `${err.response.data.status}`))
        } else if (res.isDenied) {
          this.$swal.fire(`Country was not deleted`, ``, `info`)
        }
      })
    },
    clearCountryForm() {
      this.country.id = ''; this.country.name = ''; this.country.seoTitle = ''; this.country.metaDescription = '';
      this.country.description = ''; this.country.flag = ''; this.country.photo = '';
      this.edit = false; document.querySelector('#countryForm').reset();
    }
  }
}
</script>

<style scoped>
.country-photo {
  max-width: 150px;
}
</style>