<template>
  <div>
    <h3 class="mb-3">
      Listing Gallery Photos
      <button class="btn btn-primary float-right" data-toggle="modal" data-target="#photoModal">Add New Photo</button>
    </h3>
    <!-- Photo table -->
    <table v-if="photos.length" class="table">
      <thead>
        <tr>
          <th>Preview</th>
          <th>Name</th>
          <th>Description</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(photo, i) in photos" :key="photo.id">
          <td><img :src="`${baseServerURL}/storage/gallery_photos/${photo.file}`" alt="Preview" style="max-width: 70px"></td>
          <td>{{ photo.name }}</td>
          <td>{{ photo.description }}</td>
          <td><a @click.prevent="loadPhoto(i)" href="#">Edit</a></td>
          <td><a @click.prevent="delPhoto(photo.id, i)" href="#">Delete</a></td>
        </tr>
      </tbody>
    </table>

    <!-- Photo Modal -->
    <div class="modal fade" id="photoModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ !edit ? 'Add New Photo' : 'Edit Photo' }}</h5>
            <button @click="clearPhotoForm" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="photoForm" id="photoForm">
              <div v-if="!photo.photo" class="form-group">
                <label for="photo">Photo File</label>
                <input @change="selectPhoto($event)" type="file" id="photo" class="form-control">
                <!-- <div class="text-danger" v-if="!$v.photo.photo.required && $v.photo.photo.$dirty">Photo is required</div> -->
              </div>
              <div v-else class="form-group">
                <div v-if="photo.photo && !photo.photoPreview">
                  <img :src="`${this.baseServerURL}/storage/gallery_photos/${this.photo.photo}`"
                    class="photo-photo-img" :alt="`${this.photo.name}`"
                  > <button @click.prevent="photo.photo = ''" class="btn btn-warning ml-4">Remove Photo</button>
                </div>
                <div v-if="photo.photo && photo.photoPreview">
                  <img :src="photo.photoPreview"
                    class="photo-photo-img" :alt="`${this.photo.name}`"
                  > <button 
                    @click.prevent="photo.photo = ''; photo.photoPreview = ''" 
                    class="btn btn-warning ml-4"
                  >Remove Photo</button>
                </div>
              </div>
              <div class="form-group">
                <label for="name">Photo Name</label>
                <input type="text" name="name" id="name" v-model.trim="photo.name" class="form-control">
              </div>              
              <div class="form-group">
                <label for="description">Photo Description</label>
                <textarea name="description" v-model="photo.description" 
                  id="description" cols="30" rows="3" class="form-control">
                </textarea>
              </div>
              
            </form>
          </div>
          <div class="modal-footer">
            <button @click.prevent="clearPhotoForm" type="button" class="btn btn-outline-secondary">Clear</button>
            <button @click.prevent="validateData" type="button" class="btn btn-primary">{{ !edit ? 'Add Photo' : 'Save Changes' }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      photo: { id:'', name:'', description:'', photo:'', photoPreview:'' },
      photos: [],
      apiURL: this.$apiURL,
      baseServerURL: this.$baseServerURL,
      edit: false,
    }
  },
  validations: {
    photo: {
      // photo: { required }
    }
  },
  mounted() {
    this.getPhotos();
  },
  methods: {
    validateData() {
      this.$v.$touch();
      if(!this.$v.$invalid) {
        this.sendData();
      }
    },
    sendData() {
      let vm = this;
      if(vm.edit) {
        // edit content
        let formData = new FormData(this.$refs.photoForm)
        formData.append('_method', 'PUT');
        formData.append('id', vm.photo.id)
        formData.append('photo', this.photo.photo)
        this.$axios.post(`${this.apiURL}/photos`, formData)
          .then(res => {
            if(res.status === 200) {
              // hide the modal
              this.$jquery('#photoModal').modal('hide');

              // update the DOM
              let thePhoto = this.photos.find(d => d.id === vm.photo.id)
              thePhoto.name = res.data.data.name;
              thePhoto.description = res.data.data.description;
              thePhoto.photo = res.data.data.photo;

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`).then(res => { 
                if(res.isConfirmed) vm.clearPhotoForm();
              });
            }
          })
          .catch(err => console.log(err))
      } else {
        // add content
        let formData = new FormData(this.$refs.photoForm)
        formData.append('photo', this.photo.photo)
        this.$axios.post(`${this.apiURL}/photos`, formData)
          .then(res => {
            if(res.status === 201) {
              // hide the modal
              this.$jquery('#photoModal').modal('hide');

              // update the DOM
              this.photos.push({
                id: res.data.data.id, name: res.data.data.name, description: res.data.data.description
              })

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`)
                .then(res => { 
                  if(res.isConfirmed) vm.clearPhotoForm();
                }
              );
            }
          })
          .catch(err => this.$swal.fire(`${err.response.data.title}`, `${err.response.data.message}`, `${err.response.data.status}`))
      }
    },
    selectPhoto(e) {
      this.photo.photo = e.target.files[0]; 
      this.photo.photoPreview = URL.createObjectURL(this.photo.photo)
    },
    getPhotos() {
      this.$axios.get(`${this.apiURL}/photos`)
        .then(res => this.photos = res.data)
        .catch(err => console.log(err))
    },
    loadPhoto(i) {
      this.edit = true; this.$jquery('#photoModal').modal('show');
      this.photo.id = this.photos[i].id;
      this.photo.name = this.photos[i].name;
      this.photo.description = this.photos[i].description;
      this.photo.photo = this.photos[i].photo;
    },
    delPhoto(id, i) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this Photo?',
        text:'This process is irreversible, you can\'t recover the Photo after confirming this action',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        denyButtonText: `No, don't delete`,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$axios.delete(`${this.apiURL}/photos/${id}`)
            .then(res => {
              if(res.status === 200) {
                this.photos.splice(i, 1);
                this.$swal.fire('Photo deleted..!', `${res.data.message}`, 'success');
              }
            })
            .catch(err => this.$swal.fire(`${err.response.data.title}`, `${err.response.data.message}`, `${err.response.data.status}`))
        } else if (res.isDenied) {
          this.$swal.fire('Photo was not deleted..!', '', 'info')
        }
      })
    },
    clearPhotoForm() {
      this.photo.id = ''; this.photo.name = ''; this.photo.description = ''; 
      this.photo.photo = ''; this.photo.photoPreview = ''; this.edit = false; 
      document.querySelector('#photoForm').reset(); this.$v.$reset();
    }
  }
}
</script>

<style scoped>
.photo-photo-img {
  max-width: 150px;
}
</style>