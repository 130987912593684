<template>
  <div>
    <h3 class="mb-3">
      Listing Destination Categories
      <button class="btn btn-primary float-right" data-toggle="modal" data-target="#destinationCategoryModal">
        Add New Destination Category
      </button>
    </h3>
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th class="text-center">Destinations</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(destinationCategory, i) in destinationCategories" :key="destinationCategory.id">
          <td>{{ destinationCategory.name }}</td>
          <td class="text-center">{{ destinationCategory.destinations.length ? destinationCategory.destinations.length : '---' }}</td>
          <td><a @click.prevent="loadDestinationCategory(i)" href="#">Edit</a></td>
          <td><a @click.prevent="removeDestinationCategory(destinationCategory.id, i)" href="#">Delete</a></td>
        </tr>
      </tbody>
    </table>

    <!-- Destination Category Modal -->
    <div class="modal fade" id="destinationCategoryModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ !edit ? 'Add New Destination Category' : 'Edit Destination Category' }}</h5>
            <button @click.prevent="clearDestinationCategoryForm" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="destinationCategoryForm" id="destinationCategoryForm">
              <div class="form-group">
                <label for="name">Destination Category Name</label>
                <input type="text" name="name" v-model="destinationCategory.name" id="name" class="form-control">
              </div>
              <div class="form-group">
                <label for="seo_title">Destination Category SEO Title</label>
                <span class="float-right text-black-50" v-if="destinationCategory.seoTitle">
                  {{ destinationCategory.seoTitle.length }} characters
                </span>
                <input type="text" name="seo_title" 
                  v-model="destinationCategory.seoTitle" id="seo_title" maxlength="70" class="form-control"
                >
              </div>
              <div class="form-group">
                <label for="meta_description">Meta Description</label>
                <span class="float-right text-black-50" v-if="destinationCategory.metaDescription">
                  {{ destinationCategory.metaDescription.length }} characters
                </span>
                <textarea name="meta_description" 
                  v-model="destinationCategory.metaDescription" id="meta_description" maxlength="160" cols="30" rows="2" class="form-control"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="description">Desription</label>
                <textarea name="description" 
                  v-model="destinationCategory.description" id="description" cols="30" rows="6" class="form-control"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="photo">Photo</label>
                <input type="file" name="photo" id="photo" class="form-control">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button @click.prevent="clearDestinationCategoryForm" type="button" class="btn btn-outline-secondary">Clear</button>
            <button @click.prevent="validateData" type="button" class="btn btn-primary">
              {{ !edit ? 'Add Destination Category' : 'Save Changes' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      destinationCategories: [],
      destinationCategory: {
        id:'', name:'', seoTitle:'', metaDescription:'', 
        description:'', photo:'', photoPreview:''
      },
      baseServerURL: this.$baseServerURL,
      apiURL: this.$apiURL,
      edit: false
    }
  },
  mounted() {
    this.getDestinationCategories();
  },
  methods: {
    validateData() {
      this.sendData()
    },
    sendData() {
      let vm = this;
      if(vm.edit) {
        // edit data
        let formData = new FormData(vm.$refs.destinationCategoryForm)
        formData.append('id', vm.destinationCategory.id)
        formData.append('_method', 'PUT')
        vm.$axios.post(`${vm.apiURL}/destination-categories`, formData)
          .then(res => {
            if(res.status === 200) {
              // hide the modal
              this.$jquery('#destinationCategoryModal').modal('hide');

              // update the DOM
              let theDestionationCategory = this.destinationCategories.find(d => d.id === vm.destinationCategory.id)
              theDestionationCategory.name = res.data.data.name; 
              theDestionationCategory.seo_title = res.data.data.seo_title;
              theDestionationCategory.meta_description = res.data.data.meta_description;
              theDestionationCategory.description = res.data.data.description;
              theDestionationCategory.photo = res.data.data.photo;
              theDestionationCategory.flag = res.data.data.flag;

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`)
                .then(res => { 
                  if(res.isConfirmed) vm.clearDestinationCategoryForm();
                }
              );
            }
          })
          .catch(err => console.log(err))
      } else {
        // add data
        let formData = new FormData(vm.$refs.destinationCategoryForm)
        vm.$axios.post(`${vm.apiURL}/destination-categories`, formData)
          .then(res => {
            if(res.status === 201) {
              // hide the modal
              this.$jquery('#destinationCategoryModal').modal('hide');

              // update the DOM
              this.destinationCategories.push({
                id: res.data.data.id, name: res.data.data.name, seo_title: res.data.data.seo_title,
                meta_description: res.data.data.meta_description, description: res.data.data.description,
                destinations: res.data.data.destinations
              })

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`)
                .then(res => { 
                  if(res.isConfirmed) vm.clearDestinationCategoryForm();
                }
              );
            }
          })
          .catch(err => console.log(err))
      }
    },
    getDestinationCategories() {
      this.$axios.get(`${this.apiURL}/destination-categories`)
        .then(res => this.destinationCategories = res.data)
        .catch(err => console.log(err))
    },
    loadDestinationCategory(i) {
      this.edit = true; this.$jquery('#destinationCategoryModal').modal('show');

      this.destinationCategory.id = this.destinationCategories[i].id;
      this.destinationCategory.name = this.destinationCategories[i].name;
      this.destinationCategory.seoTitle = this.destinationCategories[i].seo_title;
      this.destinationCategory.metaDescription = this.destinationCategories[i].meta_description;
      this.destinationCategory.description = this.destinationCategories[i].description;
      this.destinationCategory.photo = this.destinationCategories[i].photo;
    },
    removeDestinationCategory(id, i) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this Destination Category?',
        text:'This process is irreversible, you can\'t recover the country after confirming this action',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        denyButtonText: `No, don't delete`,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$axios.delete(`${this.apiURL}/destination-categories/${id}`)
            .then(res => {
              if(res.status === 200) {
                this.destinationCategories.splice(i, 1);
                this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`);
              }
            })
            .catch(err => this.$swal.fire(`${err.response.data.title}`, `${err.response.data.message}`, `${err.response.data.status}`))
        } else if (res.isDenied) {
          this.$swal.fire(`Category was not deleted`, ``, `info`)
        }
      })
    },
    clearDestinationCategoryForm() {
      this.destinationCategory.id = ''; this.destinationCategory.name = ''; this.destinationCategory.seoTitle = '';
      this.destinationCategory.metaDescription = ''; this.destinationCategory.photo = ''; this.destinationCategory.photoPreview = '';
      this.destinationCategory.description = ''; this.edit = false; document.querySelector('#destinationCategoryForm').reset();
    }
  }
}
</script>