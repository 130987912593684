<template>
  <div>
    <h3 class="mb-3">
      Listing Tour Categories
      <button class="btn btn-primary float-right" data-toggle="modal" data-target="#tourCategoryModal">Add New Tour Category</button>
    </h3>
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th class="text-center">Tours</th>
          <th>SEO Title</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(category, i) in tourCategories" :key="category.id">
          <td>{{ category.name }}</td>
          <td class="text-center">{{ category.tours.length ? category.tours.length : '---' }}</td>
          <td>{{ category.seo_title }}</td>
          <td><a @click.prevent="loadTourCategory(i)" href="#">Edit</a></td>
          <td><a @click="removeTourCategory(category.id, i)" href="#">Delete</a></td>
        </tr>
      </tbody>
    </table>

    <!-- Tour Category Modal -->
    <div class="modal fade" id="tourCategoryModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ !edit ? 'Add New Tour Category' : 'Edit Tour Category' }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="tourCategoryForm" id="tourCategoryForm">
              <div class="form-group">
                <label for="name">Tour Category Name</label>
                <input type="text" name="name" v-model="tourCategory.name" id="name" class="form-control">
                <div class="text-danger" v-if="!$v.tourCategory.name.required && $v.tourCategory.name.$dirty">Name is required</div>
              </div>
              <div class="form-group">
                <label for="seo_title">Tour Category SEO Title</label>
                <span v-if="tourCategory.seoTitle" class="text-black-50 float-right">{{ tourCategory.seoTitle.length }} charaters</span>
                <input type="text" name="seo_title" v-model="tourCategory.seoTitle" id="seo_title" maxlength="70" class="form-control">
              </div>
              <div class="form-group">
                <label for="meta_description">Tour Category Meta Description</label>
                <span v-if="tourCategory.metaDescription" class="text-black-50 float-right">
                  {{ tourCategory.metaDescription.length }} charaters
                </span>
                <textarea name="meta_description" v-model="tourCategory.metaDescription"
                  id="meta_description" cols="30" rows="2" maxlength="160" class="form-control"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="description">Tour Category Description</label>
                <textarea name="description" v-model="tourCategory.description" id="description" cols="30" rows="4" class="form-control"></textarea>
              </div>
              <div class="form-group">
                <div v-if="!tourCategory.photo">
                  <label for="photo">Tour Category Photo</label>
                  <input @change="selectPhoto($event)" type="file" id="photo" class="form-control">
                  <div class="text-danger" v-if="!$v.tourCategory.photo.required && $v.tourCategory.photo.$dirty">Photo is required</div>
                </div>
                <div v-else>
                  <div v-if="tourCategory.photo && !tourCategory.photoPreview">
                    <img 
                      :src="`${baseServerURL}/storage/tour_category_photos/${tourCategory.photo}`" 
                      class="category-photo" :alt="`${tourCategory.name}`"
                    >
                    <button @click.prevent="tourCategory.photo = ''" class="btn btn-warning ml-3">Remove Photo</button>
                  </div>
                  <div v-else-if="tourCategory.photo && tourCategory.photoPreview">
                    <img :src="`${tourCategory.photoPreview}`" class="category-photo" alt="Preview">
                    <button @click.prevent="tourCategory.photo = ''; tourCategory.photoPreview = ''" class="btn btn-warning ml-3">
                      Remove Photo
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button @click.prevent="clearTourCategoryForm" type="button" class="btn btn-outline-secondary">Clear</button>
            <button @click.prevent="validateData" type="button" class="btn btn-primary">
              {{ !edit ? 'Add New Tour Category' : 'Save Changes' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      tourCategory: {
        id:'', name:'', seoTitle:'', metaDescription:'', photo:'', photoPreview:''
      },
      baseServerURL: this.$baseServerURL,
      apiURL: this.$apiURL,
      tourCategories: [],
      edit: false,
    }
  },
  validations: {
    tourCategory: {
      name: { required },
      photo: { required }
    }
  },
  mounted() {
    this.getTourCategories();
  },
  methods: {
    validateData() {
      this.$v.$touch();
      if(!this.$v.$invalid) {
        this.sendData()
      }
    },
    sendData() {
      let vm = this;
      if(vm.edit) {
        // edit content
        let formData = new FormData(vm.$refs.tourCategoryForm)
        formData.append('photo', vm.tourCategory.photo)
        formData.append('id', vm.tourCategory.id)
        formData.append('_method', 'PUT')
        vm.$axios.post(`${vm.apiURL}/tour-categories`, formData)
          .then(res => {
            if(res.status === 200) {
              // hide the modal
              this.$jquery('#tourCategoryModal').modal('hide');

              // update the DOM
              let theCategory = vm.tourCategories.find(c => c.id === vm.tourCategory.id)
              theCategory.id = res.data.data.id; 
              theCategory.name = res.data.data.name;
              theCategory.seo_title = res.data.data.seo_title;
              theCategory.meta_description = res.data.data.meta_description;
              theCategory.description = res.data.data.description;
              theCategory.photo = res.data.data.photo;

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`)
                .then(res => { 
                  if(res.isConfirmed) vm.clearTourCategoryForm();
                }
              );
            }
          })
          .catch(err => this.$swal.fire(
              `${err.response.data.title}`, 
              `${err.response.data.message}. ${err.message}`, 
              `${err.response.data.status}`
            )
          )
      } else {
        console.log('adding content...')
        // add content
        let formData = new FormData(vm.$refs.tourCategoryForm);
        formData.append('photo', vm.tourCategory.photo)
        vm.$axios.post(`${vm.apiURL}/tour-categories`, formData)
          .then(res => { console.log(res)
            if(res.status === 201) {
              // hide the modal
              this.$jquery('#tourCategoryModal').modal('hide');

              // update the DOM
              vm.tourCategories.push({
                id: res.data.data.id, name: res.data.data.name, seo_title: res.data.data.seo_title, 
                meta_description: res.data.data.meta_description, photo: res.data.data.photo,
                tours: res.data.data.tours
              })

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`)
                .then(res => { 
                  if(res.isConfirmed) vm.clearTourCategoryForm();
                }
              );
            }
          })
          .catch(err => this.$swal.fire(
            `${err.response.data.title}`,
            `${err.response.data.message}. ${err.message}`,
            `${err.response.data.status}`
            )
          )
      }
    },
    selectPhoto(e) {
      this.tourCategory.photo = e.target.files[0];
      this.tourCategory.photoPreview = URL.createObjectURL(this.tourCategory.photo);
    },
    getTourCategories() {
      this.$axios.get(`${this.apiURL}/tour-categories`)
        .then(res => this.tourCategories = res.data)
        .catch(err => console.log(err))
    },
    loadTourCategory(i) {
      this.edit = true; this.$jquery('#tourCategoryModal').modal('show');

      this.tourCategory.id = this.tourCategories[i].id;
      this.tourCategory.name = this.tourCategories[i].name;
      this.tourCategory.seoTitle = this.tourCategories[i].seo_title;
      this.tourCategory.metaDescription = this.tourCategories[i].meta_description;
      this.tourCategory.description = this.tourCategories[i].description;
      this.tourCategory.photo = this.tourCategories[i].photo;
    },
    removeTourCategory(id, i) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this Tour Category?',
        text:'This process is irreversible, you can\'t recover the destination after confirming this action',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        denyButtonText: `No, don't delete`,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$axios.delete(`${this.apiURL}/tour-categories/${id}`)
            .then(res => {
              if(res.status === 200) {
                this.tourCategories.splice(i, 1);
                this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`);
              }
            })
            .catch(err => this.$swal.fire(`${err.response.data.title}`, `${err.response.data.message}`, `${err.response.data.status}`))
        } else if (res.isDenied) {
          this.$swal.fire('Destination was not deleted..!', '', 'info')
        }
      })
    },
    clearTourCategoryForm() {
      this.tourCategory.id = ''; this.tourCategory.name = ''; this.tourCategory.seoTitle = ''; 
      this.tourCategory.metaDescription = ''; this.tourCategory.description = ''; this.tourCategory.photo = '';
      document.querySelector('#tourCategoryForm').reset(); this.edit = false; this.$v.$reset()
    }
  }
}
</script>

<style scoped>
.category-photo {
  max-width: 150px;
}
</style>