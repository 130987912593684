import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import Vuelidate from 'vuelidate';
import VueRouter from 'vue-router';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import 'bootstrap';


import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// sweet alert options
const sweetAlertoptions = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
}

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2, sweetAlertoptions);
Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(CKEditor);

// export International currency formatter
export const formatter = new Intl.NumberFormat('en-US',{
  style: 'currency',
  currency: 'USD'
})

// libaries & options for global use
import Axios from 'axios'
import jQuery from 'jquery'

Vue.prototype.$axios = Axios;
Vue.prototype.$jquery = jQuery;
Vue.prototype.$apiURL = 'https://aasafari.com/core/api/v1';
// Vue.prototype.$apiURL = 'http://localhost:8000/api/v1';
Vue.prototype.$baseServerURL = 'https://dashboard.aasafari.com';
// Vue.prototype.$baseServerURL = 'http://localhost:8000';

// import Components for use in routes
import DestinationCategory from '@/components/DestinationCategory'
import Destinations from '@/components/Destinations'
import TourCategory from '@/components/TourCategory'
import Country from '@/components/Country'
import Gallery from '@/components/Gallery'
import Tours from '@/components/Tours'
import Pages from '@/components/Pages'

const routes = [
  { path: '/destination-categories', component: DestinationCategory },
  { path: '/destinations', component: Destinations },
  { path: '/tour-categories', component: TourCategory },
  { path: '/countries', component: Country },
  { path: '/gallery', component: Gallery },
  { path: '/tours', component: Tours },
  { path: '/pages', component: Pages },
]

const router = new VueRouter({
  routes
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
