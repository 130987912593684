<template>
  <div>
    <h3 class="mb-3">
      Listing Destinations
      <button class="btn btn-primary float-right" data-toggle="modal" data-target="#destinationModal">Add New Destination</button>
    </h3>
    <table class="table">
      <thead>
        <tr>
          <th class="text-center">ID</th>
          <th>Name</th>
          <th>Category</th>
          <th>Country</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(destination, i) in destinations" :key="destination.id">
          <td class="text-center">{{ destination.id }}</td>
          <td>{{ destination.name }}</td>
          <td>{{ destination.category.name }}</td>
          <td>{{ destination.country.name }}</td>
          <td><a @click.prevent="loadDestination(i)" href="#">Edit</a></td>
          <td><a @click.prevent="removeDestination(destination.id, i)" href="#">Delete</a></td>
        </tr>
      </tbody>
    </table>

    <!-- Destinations Table -->
    <div class="modal fade" id="destinationModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ !edit ? 'Add New Destination' : 'Edit Destination' }}</h5>
            <button @click="clearDestinationForm" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="destinationForm" id="destinationForm">
              <div class="form-group">
                <label for="name">Destination Name</label>
                <input type="text" name="name" id="name" v-model.trim="destination.name" class="form-control">
                <div class="text-danger" v-if="!$v.destination.name.required && $v.destination.name.$dirty">Name is required</div>
              </div>
              <div class="row">
                <div class="col-lg-6 form-group">
                  <label for="country_id">Select Country</label>
                  <select name="country_id" v-model="destination.countryID" id="country_id" class="form-control">
                    <option value="" selected disabled>--- Select Country ---</option>
                    <option v-for="country in countries" :value="country.id" :key="country.id">{{ country.name }}</option>
                  </select>
                  <div class="text-danger" v-if="!$v.destination.countryID.required && $v.destination.countryID.$dirty">
                    Country is required
                  </div>
                </div>
                <div class="col-lg-6 form-group">
                  <label for="destination_category_id">Select Destination Category</label>
                  <select name="destination_category_id" 
                    v-model="destination.destinationCategoryID" 
                    id="destination_category_id" class="form-control"
                  >
                    <option value="" selected disabled>--- Select Destination Category ---</option>
                    <option v-for="destinationCategory in destinationCategories" 
                      :value="destinationCategory.id" 
                      :key="destinationCategory.id">{{ destinationCategory.name }}
                    </option>
                  </select>
                  <div class="text-danger" 
                    v-if="!$v.destination.destinationCategoryID.required 
                    && $v.destination.destinationCategoryID.$dirty">
                      Category is required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="seo_title">SEO Title</label> <span v-if="destination.seoTitle" class="float-right text-black-50">{{ destination.seoTitle.length }} characters</span>
                <input type="text" name="seo_title" v-model="destination.seoTitle" id="seo_title" maxlength="70" class="form-control">
              </div>
              <div class="form-group">
                <label for="meta_desctiption">Meta Description</label> <span v-if="destination.metaDescription" class="float-right text-black-50">{{ destination.metaDescription.length }} characters</span>
                <textarea name="meta_description" v-model="destination.metaDescription" maxlength="160" id="meta_description" cols="30" rows="2" class="form-control"></textarea>
              </div>
              <div class="form-group">
                <label for="description">Destination Description</label>
                <ckeditor :editor="editor" v-model="destination.description" :config="editorConfig"></ckeditor>
                <div class="text-danger" 
                  v-if="!$v.destination.description.required 
                  && $v.destination.description.$dirty">
                    Description is required
                </div>
              </div>
              <div v-if="!destination.photo" class="form-group">
                <label for="photo">Destination Photo</label>
                <input @change="selectPhoto($event)" type="file" id="photo" class="form-control">
                <div class="text-danger" v-if="!$v.destination.photo.required && $v.destination.photo.$dirty">Photo is required</div>
              </div>
              <div v-else class="form-group">
                <div v-if="destination.photo && !destination.photoPreview">
                  <img :src="`${this.baseServerURL}/storage/destination_photos/${this.destination.photo}`"
                    class="destination-photo-img" :alt="`${this.destination.name}`"
                  > <button @click.prevent="destination.photo = ''" class="btn btn-warning ml-4">Remove Photo</button>
                </div>
                <div v-if="destination.photo && destination.photoPreview">
                  <img :src="destination.photoPreview"
                    class="destination-photo-img" :alt="`${this.destination.name}`"
                  > <button 
                    @click.prevent="destination.photo = ''; destination.photoPreview = ''" 
                    class="btn btn-warning ml-4"
                  >Remove Photo</button>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button @click.prevent="clearDestinationForm" type="button" class="btn btn-outline-secondary">Clear</button>
            <button @click.prevent="validateData" type="button" class="btn btn-primary">{{ !edit ? 'Add Destination' : 'Save Changes' }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  data() {
    return {
      destination: {
        id:'', countryID:'', destinationCategoryID:'', name:'',
        seoTitle:'', metaDescription:'', description:'', photo:'', photoPreview:''
      },
      apiURL: this.$apiURL,
      baseServerURL: this.$baseServerURL,
      countries: [],
      destinations: [],
      destinationCategories: [],
      edit: false,
      editor: ClassicEditor,
      editorConfig: { }, // The configuration of the editor.
    }
  },
  validations: {
    destination: {
      name: { required },
      countryID: { required },
      destinationCategoryID: { required },
      description: { required },
      photo: { required }
    }
  },
  mounted() {
    this.getCountries();
    this.getDestinations();
    this.getDestinationCategories();
  },
  methods: {
    validateData() {
      this.$v.$touch();
      if(!this.$v.$invalid) {
        this.sendData();
      }
    },
    sendData() {
      let vm = this;
      if(vm.edit) {
        // edit content
        let formData = new FormData(this.$refs.destinationForm)
        formData.append('_method', 'PUT');
        formData.append('id', vm.destination.id)
        formData.append('photo', this.destination.photo)
        formData.append('description', vm.destination.description)
        this.$axios.post(`${this.apiURL}/destinations`, formData)
          .then(res => {
            if(res.status === 200) {
              // hide the modal
              this.$jquery('#destinationModal').modal('hide');

              // update the DOM
              let theDestination = this.destinations.find(d => d.id === vm.destination.id)
              theDestination.country = res.data.data.country;
              theDestination.category = res.data.data.category;
              theDestination.name = res.data.data.name; theDestination.seoTitle = res.data.data.seo_title;
              theDestination.meta_description = res.data.data.meta_description;
              theDestination.description = res.data.data.description;
              theDestination.photo = res.data.data.photo;

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`).then(res => { 
                if(res.isConfirmed) vm.clearDestinationForm();
              });
            }
          })
          .catch(err => console.log(err))
      } else {
        // add content
        let formData = new FormData(this.$refs.destinationForm)
        formData.append('photo', this.destination.photo)
        formData.append('description', vm.destination.description)
        this.$axios.post(`${this.apiURL}/destinations`, formData)
          .then(res => {
            if(res.status === 201) {
              // hide the modal
              this.$jquery('#destinationModal').modal('hide');

              // update the DOM
              this.destinations.push({
                id: res.data.data.id, name: res.data.data.name, seo_title: res.data.data.seo_title,
                meta_description: res.data.data.meta_description, description: res.data.data.description,
                country: res.data.data.country, category: res.data.data.category
              })

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`)
                .then(res => { 
                  if(res.isConfirmed) vm.clearDestinationForm();
                }
              );
            }
          })
          .catch(err => this.$swal.fire(`${err.response.data.title}`, `${err.response.data.message}`, `${err.response.data.status}`))
      }
    },
    selectPhoto(e) {
      this.destination.photo = e.target.files[0]; 
      this.destination.photoPreview = URL.createObjectURL(this.destination.photo)
    },
    getCountries() {
      this.$axios.get(`${this.apiURL}/countries`)
        .then(res => this.countries = res.data)
        .catch(err => console.log(err))
    },
    getDestinations() {
      this.$axios.get(`${this.apiURL}/destinations`)
        .then(res => this.destinations = res.data)
        .catch(err => console.log(err))
    },
    getDestinationCategories() {
      this.$axios.get(`${this.apiURL}/destination-categories`)
        .then(res => this.destinationCategories = res.data)
        .catch(err => console.log(err))
    },
    loadDestination(i) {
      this.edit = true; this.$jquery('#destinationModal').modal('show');
      this.destination.id = this.destinations[i].id;
      this.destination.countryID = this.destinations[i].country_id;
      this.destination.destinationCategoryID = this.destinations[i].destination_category_id;
      this.destination.name = this.destinations[i].name;
      this.destination.seoTitle = this.destinations[i].seo_title;
      this.destination.metaDescription = this.destinations[i].meta_description
      this.destination.description = this.destinations[i].description
      this.destination.photo = this.destinations[i].photo;
    },
    removeDestination(id, i) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this Destination?',
        text:'This process is irreversible, you can\'t recover the destination after confirming this action',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        denyButtonText: `No, don't delete`,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$axios.delete(`${this.apiURL}/destinations/${id}`)
            .then(res => {
              if(res.status === 200) {
                this.destinations.splice(i, 1);
                this.$swal.fire('Destination deleted..!', `${res.data.message}`, 'success');
              }
            })
            .catch(err => this.$swal.fire(`${err.response.data.title}`, `${err.response.data.message}`, `${err.response.data.status}`))
        } else if (res.isDenied) {
          this.$swal.fire('Destination was not deleted..!', '', 'info')
        }
      })
    },
    clearDestinationForm() {
      this.destination.id = ''; this.destination.countryID = ''; this.destination.destinationCategoryID = '';
      this.destination.name = ''; this.destination.seoTitle = ''; this.destination.metaDescription = '';
      this.destination.description = ''; this.destination.photo = ''; this.destination.photoPreview = '';
      this.edit = false; document.querySelector('#destinationForm').reset(); this.$v.$reset()
    }
  }
}
</script>

<style scoped>
.destination-photo-img {
  max-width: 150px;
}
</style>