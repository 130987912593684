<template>
  <div>
    <h3 class="mb-3">
      Listing Pages
      <button class="btn btn-primary float-right" data-toggle="modal" data-target="#pageModal">Add New Page</button>
    </h3>
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>SEO Title</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(page, i) in pages" :key="page.id">
          <td>{{ page.name }}</td>
          <td>{{ page.seo_title }}</td>
          <td><a @click.prevent="loadPage(i)" href="#">Edit</a></td>
          <td><a @click.prevent="removePage(page.id, i)" href="#">Delete</a></td>
        </tr>
      </tbody>
    </table>

    <!-- Page Modal -->
    <div class="modal fade" id="pageModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ !edit ? 'Add New Page' : `Edit ${page.name} Page details` }}</h5>
            <button @click="clearPageForm" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="pageForm" id="pageForm">
              <div class="form-group">
                <label for="name">Page Name</label>
                <input type="text" name="name" v-model="page.name" id="name" class="form-control">
              </div>
              <div class="form-group">
                <label for="seo_title">SEO Title</label> 
                <span v-if="page.seoTitle" class="float-right text-black-50">{{ page.seoTitle.length }} characters</span>
                <input type="text" name="seo_title" v-model="page.seoTitle" id="seo_title" class="form-control">
              </div>
              <div class="form-group">
                <label for="meta_description">Meta Description</label> 
                <span v-if="page.metaDescription" class="float-right text-black-50">
                  {{ page.metaDescription.length }} characters
                </span>
                <textarea type="text" name="meta_description" v-model="page.metaDescription" 
                  id="meta_description" cols="30" rows="2" class="form-control"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="description">Page Description</label>
                <ckeditor :editor="editor" v-model="page.description" :config="editorConfig"></ckeditor>
              </div>
              <div class="row">
                <!-- <div class="col-lg-6 form-group">
                  <div v-if="!country.flag">
                    <label for="flag">Country Flag</label>
                    <input type="file" name="flag" id="flag" class="form-control">
                  </div>
                  <div v-else>
                    <img :src="`${baseServerURL}/storage/country_flags/${country.flag}`" :alt="`${country.name}`">
                    <button @click.prevent="country.flag = ''" class="btn btn-warning ml-3">Remove Photo</button>
                  </div>
                </div> -->
                <div class="col-lg-6 form-group">
                  <div v-if="!page.photo">
                    <label for="photo">Page Photo</label>
                    <input type="file" name="photo" id="photo" class="form-control">
                  </div>
                  <div v-else>
                    <img :src="`${baseServerURL}/storage/page_photos/${page.photo}`" class="page-photo" :alt="`${page.name}`">
                    <button @click.prevent="page.photo = ''" class="btn btn-warning ml-3">Remove Photo</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button @click.prevent="clearPageForm" type="button" class="btn btn-outline-secondary">Clear</button>
            <button @click.prevent="validateData" type="button" class="btn btn-primary">
              {{ !edit ? 'Add Page' : 'Save Changes' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  data() {
    return {
      pages: [],
      page: { 
        id:'', name:'', seoTitle:'', metaDescription:'', 
        description:'', photo:''
      },
      apiURL: this.$apiURL,
      baseServerURL: this.$baseServerURL,
      editor: ClassicEditor,
      editorConfig: {},
      edit: false
    }
  },
  mounted() {
    this.getPages();
  },
  methods: {
    validateData() {
      this.sendData()
    },
    sendData() {
      let vm = this;
      if(this.edit) {
        // edit data
        let formData = new FormData(this.$refs.pageForm)
        formData.append('description', vm.page.description);
        formData.append('id', vm.page.id)
        formData.append('_method', 'PUT')
        this.$axios.post(`${this.apiURL}/pages`, formData)
          .then(res => {
            if(res.status === 200) {
              // hide the modal
              this.$jquery('#pageModal').modal('hide');

              // update the DOM
              let thePage = this.pages.find(c => c.id === vm.page.id)
              thePage.name = res.data.data.name; 
              thePage.seo_title = res.data.data.seo_title;
              thePage.meta_description = res.data.data.meta_description; 
              thePage.description = res.data.data.description;
              thePage.photo = res.data.data.photo;

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`)
                .then(res => { 
                  if(res.isConfirmed) vm.clearPageForm();
                }
              );
            }
          })
          .catch(err => console.log(err))
      } else {
        // add data
        let formData = new FormData(this.$refs.pageForm)
        formData.append('description', vm.page.description);
        this.$axios.post(`${this.apiURL}/pages`, formData)
          .then(res => {
            if(res.status === 201) {
              // hide the modal
              this.$jquery('#pageModal').modal('hide');

              // update the DOM
              this.pages.push({
                id: res.data.data.id, name: res.data.data.name, seo_title: res.data.data.seo_title,
                meta_description: res.data.data.meta_description, description: res.data.data.description
              })

              // notify the user
              this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`)
                .then(res => { 
                  if(res.isConfirmed) vm.clearPageForm();
                }
              );
            }
          })
          .catch(err => console.log(err))
      }
    },
    getPages() {
      this.$axios.get(`${this.apiURL}/pages`)
        .then(res => this.pages = res.data)
        .catch(err => console.log(err))
    },
    loadPage(i) {
      this.edit = true; this.$jquery('#pageModal').modal('show');
      
      this.page.id = this.pages[i].id;
      this.page.name = this.pages[i].name;
      this.page.seoTitle = this.pages[i].seo_title;
      this.page.metaDescription = this.pages[i].meta_description;
      this.page.description = this.pages[i].description;
      this.page.flag = this.pages[i].flag
      this.page.photo = this.pages[i].photo
    },
    removePage(id, i) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this Page?',
        text:'This process is irreversible, you can\'t recover the page after confirming this action',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        denyButtonText: `No, don't delete`,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$axios.delete(`${this.apiURL}/pages/${id}`)
            .then(res => {
              if(res.status === 200) {
                this.pages.splice(i, 1);
                this.$swal.fire(`${res.data.title}`, `${res.data.message}`, `${res.data.status}`);
              }
            })
            .catch(err => this.$swal.fire(`${err.response.data.title}`, `${err.response.data.message}`, `${err.response.data.status}`))
        } else if (res.isDenied) {
          this.$swal.fire(`Page was not deleted`, ``, `info`)
        }
      })
    },
    clearPageForm() {
      this.page.id = ''; this.page.name = ''; this.page.seoTitle = ''; this.page.metaDescription = '';
      this.page.description = ''; this.page.photo = ''; this.edit = false; 
      document.querySelector('#pageForm').reset();
    }
  }
}
</script>

<style scoped>
.page-photo {
  max-width: 150px;
}
</style>